function ContactData() {
  return (
    <div>
      <div className="contact noselect">
        <a href="mailto:hi@pago.codes">hi@pago.codes</a>
      </div>
    </div>
  );
}

export default ContactData;
